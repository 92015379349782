@charset "utf-8";

// change default font
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
$family-sans-serif: "Source Sans Pro", sans-serif;

// over ride progress bar colors
$success: hsl(76, 73%, 58%);
$danger: hsl(0,100%,50%);
$primary: hsl(76, 73%, 58%);
$button-background-color: hsl(76, 73%, 58%);
$button-hover-background-color:  hsl(196, 23%, 20%);

@import "../node_modules/bulma/bulma.sass";

.button:hover {
  background-color: hsl(196, 23%, 20%);
  color: hsl(76, 73%, 58%);
}

.card {
  /* remove shadow from the right site of the card */
  box-shadow: unset;
}

.card-job-profile:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.cards-container {
  margin: 1em;
}

.modal-background {
  background-color: rgba(255,255,255,.90);
}

.job-relation-card-text {
  height: 8em;
}

/* Blur the text that needs to be hidden */
.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

/* Start -Slider specific */

.slider-column-width-100 {
  /* must be set to avoid overflow of the parent box */
  width: 100%;
}

#slider-container-outer {
  overflow: hidden;
}

#slider-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

/* CSS transition applied when translation of items happen */
.slider-container-transition {
  transition: transform 0.7s ease-in-out;
}

.slider-item {
  width: 100%;
  flex-shrink: 0;
}

/* End -Slider specific */

